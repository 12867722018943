body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 0 20px;
  }
  .layout,
  .RaLayout-content {
    background-color: white !important;
  }
  .MuiDrawer-root,
  .MuiAppBar-root,
  .MuiTabs-root,
  .MuiDivider-root,
  .MuiToolbar-root,
  .MuiToolbar-root {
    display: none !important;
  }
  .MuiCard-root {
    box-shadow: none !important;
  }
}
